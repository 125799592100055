// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bv_kw";
export var caseStudyBackground__lineColor = "bv_kt";
export var caseStudyHead = "bv_kn";
export var caseStudyHead__imageWrapper = "bv_kp";
export var caseStudyProjectsSection = "bv_kx";
export var caseStudyQuote__bgLight = "bv_kD";
export var caseStudyQuote__bgRing = "bv_kr";
export var caseStudyVideo = "bv_kS";
export var caseStudyVideo__ring = "bv_kG";
export var caseStudy__bgDark = "bv_km";
export var caseStudy__bgLight = "bv_kl";